export default {
  office365: require('../assets/images/office-365.svg').default,
  googleIcon: require('../assets/images/google-icon.svg').default,
  outlookIcon: require('../assets/images/providers/outlook.svg').default,
  office365Icon: require('../assets/images/office-365-withoutText.svg').default,
  yahooIcon: require('../assets/images/providers/yahoo.svg').default,
  personalIcon: require('../assets/images/providers/personal.svg').default,
  conferenceIcon: require('../assets/images/providers/conference.svg').default,
  companyIcon: require('../assets/images/providers/company.svg').default,
  voicemailIcon: require('../assets/images/empty-msg.svg').default,
};
