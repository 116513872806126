import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Phone } from '@mui/icons-material';

import ActivityIndicator from '../common/ActivityIndicator';
import EmptyState from '../common/EmptyState';
import { getCumulativeCallHistory } from '../../../callLogs/selectors/callLogsSelector';
import ActivityList from '../activities/ActivityList';

import CallEventItem from './CallEventItem';

const CallHistory = ({ fetching, cumulativeHistory, t }) => {
  if (fetching) {
    return <ActivityIndicator id="spinner" label={t('loadingHistory')} />;
  }

  if (cumulativeHistory.length === 0) {
    return <EmptyState icon={Phone}>{t('emptyStateCalls')}</EmptyState>;
  }

  return (
    <ActivityList
      activities={cumulativeHistory.map(e => {
        e.callLog.cumulativeQuantity = e.quantity;
        return e.callLog;
      })}
      callLogRenderer={CallEventItem}
      groupByTime={false}
      id="activity-list"
    />
  );
};

const mapStateToProps = state => ({
  cumulativeHistory: getCumulativeCallHistory(state),
  fetching: state.callLogs.fetching,
});

export default connect(mapStateToProps)(React.memo(withTranslation('activities')(CallHistory)));
