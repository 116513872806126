import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import CallHistory from '../callLogs/CallHistory';
import VoicemailHistory from '../voicemails/VoicemailHistory';

import Logout from './Logout';

const RootContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const StyledTabs = styled(Tabs)`
  && {
    box-shadow: 0 3px 6px rgba(0,0,0,.1);
  }
`;

const StyledTab = styled(Tab)`
  && {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

export const Activities = ({ t }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, tabIndex) => {
    setCurrentTab(tabIndex);
  };

  return (
    <RootContainer id="higher-activities">
      <StyledTabs centered id="tabs" indicatorColor="primary" onChange={handleTabChange} value={currentTab}>
        <StyledTab label={t('callHistory')} />
        <StyledTab label={t('voicemails')} />
        <Logout />
      </StyledTabs>

      {currentTab === 0 && <CallHistory id="call-history" />}

      {currentTab === 1 && <VoicemailHistory id="voicemail-history" />}
    </RootContainer>
  );
};

export default withTranslation('activities')(Activities);
