import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Typography } from '@mui/material';

import colors from '../../../main/themes/colors';
import { getCallLogDuration } from '../../../callLogs/selectors/callLogsSelector';
import { media } from '../utils';

const VerticalDivider = styled.div`
  margin-left: 8px;
  margin-right: 8px;
  height: 10px;
  border-right: 1px solid ${colors.border};
  width: 1px;
`;

const Content = styled(Typography)`
  && {
    ${media.md} {
      font-size: 12px;
    }
  }
`;

const ONE_HOUR = 3600 * 1000;

const Duration = ({ callLog, session }) => {
  const duration = getCallLogDuration(callLog, session);
  if (!duration) {
    return null;
  }

  const format = duration > ONE_HOUR ? 'hh:mm:ss' : 'mm:ss';

  return (
    <>
      <VerticalDivider className="divider hide-on-mobile" />
      <Content>{moment.utc(duration).format(format)}</Content>
    </>
  );
};

export default React.memo(Duration);
