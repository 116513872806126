import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import colors from '../../../main/themes/colors';

import TitledDivider from './TitledDivider';

const StyledTitledDivider = styled(TitledDivider)`
  margin-top: 15px !important;
  margin-bottom: 25px !important;
  & > span > p {
    color: ${colors.textDivider} !important;
  }
`;

const isSameDay = (a, b) =>
  a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();

const THIS_YEAR_FORMAT = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
};

const OLDER_THAN_ONE_YEAR_FORMAT = {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
};

const capitalizeFirstLetter = input => input.charAt(0).toUpperCase() + input.slice(1);

export const getSectionTitle = (date, t) => {
  const today = new Date();
  const language = window.localStorage.i18nextLng || window.navigator.language;

  if (isSameDay(date, today)) {
    return t('today');
  }

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (isSameDay(date, yesterday)) {
    return t('yesterday');
  }

  if (date.getFullYear() === today.getFullYear()) {
    const formatedDate = date.toLocaleDateString(language, THIS_YEAR_FORMAT);

    return capitalizeFirstLetter(formatedDate);
  }

  const formatedDate = date.toLocaleDateString(language, OLDER_THAN_ONE_YEAR_FORMAT);

  return capitalizeFirstLetter(formatedDate);
};

export const DateTitledDivider = ({ date, t }) => (
  <StyledTitledDivider id="title" title={getSectionTitle(date, t)} />
);

export default withTranslation('activities')(React.memo(DateTitledDivider));
