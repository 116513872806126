import React from 'react';

import PersonalAvatar from './PersonalAvatar';

export const Avatar = ({
  contact,
  displayName,
  spacing = 5,
  size = 32,
  ...rest
}) => (
  <PersonalAvatar
    contact={contact}
    displayName={displayName}
    size={size}
    spacing={spacing}
    {...rest}
  />
);

export default React.memo(Avatar);
