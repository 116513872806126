import React from 'react';
import Typography from '@mui/material/Typography/Typography';
import styled from 'styled-components';

import Initials from '../../../contact/domain/Initials';
import colors from '../../../main/themes/colors';

import ContactState from './ContactState';

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledContactState = styled(ContactState)`
  position: absolute;
  bottom: 0;
  right: -2px;
`;

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: ${props => props.size + props.spacing}px;
  height: ${props => props.size + props.spacing}px;
  border-radius: ${props => props.size}px;
  background-color: ${colors.primary};
`;

const AvatarText = styled(Typography)`
  color: ${colors.contrastingText} !important;
  font-size: ${props => (props.size ? `${props.size / 2}px !important` : 'initial')};
`;

export const PersonalAvatar = ({
  contact,
  displayName,
  spacing = 5,
  size = 32,
  stateSize = 30,
  ...rest
}) => (
  <AvatarContainer {...rest}>
    <Container className="avatar" size={size} spacing={spacing}>
      <AvatarText size={size} variant="body2">
        <span id="name">{Initials.parse(!!contact && contact.name ? contact.name : displayName)}</span>
      </AvatarText>
      <StyledContactState contact={contact} size={stateSize} />
    </Container>
  </AvatarContainer>
);

export default React.memo(PersonalAvatar);
