import { createSelector } from 'reselect';

import { getActivityDate } from '../../callLogs/selectors/callLogsSelector';

const fetchingSelector = state => state.voicemails.fetching;
export const isFetchingVoicemails = createSelector(
  fetchingSelector,
  fetching => fetching,
);

const voicemailSelector = state => state.voicemails.voicemails;
export const getVoicemails = createSelector(
  voicemailSelector,
  voicemails => voicemails,
);

export const getVoicemailHistory = createSelector(
  voicemailSelector,
  activities => (activities || []).sort((a, b) => getActivityDate(b) - getActivityDate(a)),
);
