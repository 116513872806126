import { useEffect } from 'react';

let timerInterval;

const Timer = ({ callback, interval }) => {
  useEffect(() => {
    timerInterval = setInterval(callback, interval);

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  return null;
};

export default Timer;
