import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';

import colors from '../../../main/themes/colors';

const EmptyStateContainer = styled.div`
  && {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    justify-content: center;
  }
`;

const EmptyStateLabel = styled(Typography)`
  display: flex !important;
  justify-content: center !important;
  max-width: 525px !important;
  text-align: center !important;
`;

const StyledIcon = styled(
  ({ component, ...props }) => React.cloneElement(component, props),
)`
  width: 62px !important;
  height: 54px !important;
  display: flex !important;
  color: ${colors.emptyState} !important;
`;

const EmptyState = ({ icon: Icon, img, children }) => (
  <EmptyStateContainer>
    {Icon && <StyledIcon component={<Icon />} />}
    {img}
    <EmptyStateLabel>{children}</EmptyStateLabel>
  </EmptyStateContainer>
);

export default EmptyState;
