import { select, takeEvery } from 'redux-saga/effects';
import ReconnectingWebSocket from 'reconnecting-websocket';

import {
  DEEP_LINK_OPEN,
  onDeepLinkConnected,
  onDeepLinkDisconnected,
  shouldDisplayDeepLinkWarning,
} from '../actions/deepLinkActions';
import { AUTHENTICATION_SUCCESS, LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../../../../user/actions/userActions';

window.deepLinkWebsocket = null;
let warningTimeout = null;
const wdaPort = 41414;
const warningTimeoutDelay = 5000; // in ms

const onWebsocketMessage = ({ data }) => {
  try {
    const message = JSON.parse(data);
    if (!message) {
      return;
    }

    if (message.op === 'checkToken') {
      if (message.result) {
        clearTimeout(warningTimeout);
        warningTimeout = null;
        window.store.dispatch(shouldDisplayDeepLinkWarning(false));
        window.store.dispatch(onDeepLinkConnected());
      }
      deepLinkWebsocket.removeEventListener('message', onWebsocketMessage);
    }
  } catch {
    // Nothing to do
  }
};

const startTimeoutDelay = () => {
  if (warningTimeout) {
    return;
  }

  warningTimeout = setTimeout(() => {
    window.store.dispatch(shouldDisplayDeepLinkWarning(true));
    warningTimeout = null;
  }, warningTimeoutDelay);
};

function* onStartDeepLinkConnection({ payload }) {
  const { token } = payload.session;
  const host = window.localStorage.getItem('server');

  startTimeoutDelay();

  window.deepLinkWebsocket = new ReconnectingWebSocket(`ws://localhost:${wdaPort}`);

  deepLinkWebsocket.addEventListener('open', () => {
    deepLinkWebsocket.send(JSON.stringify({ 'X-Auth-Token': token, host }));

    deepLinkWebsocket.addEventListener('message', onWebsocketMessage);
  });

  deepLinkWebsocket.addEventListener('close', () => {
    startTimeoutDelay();
    window.store.dispatch(onDeepLinkDisconnected());
  });
}

function* onStopDeepLinkConnection() {
  if (deepLinkWebsocket) {
    deepLinkWebsocket.close();
  }
}

function* onOpenLink({ payload }) {
  const { deepLink: { connected } } = yield select();

  if (!connected && !window.playwright) {
    return;
  }

  const { link } = payload;
  deepLinkWebsocket.send(JSON.stringify({ link }));
}

export default [
  takeEvery(AUTHENTICATION_SUCCESS, onStartDeepLinkConnection),
  takeEvery(LOGIN_SUCCESS, onStartDeepLinkConnection),
  takeEvery(LOGOUT_SUCCESS, onStopDeepLinkConnection),
  takeEvery(DEEP_LINK_OPEN, onOpenLink),
];
