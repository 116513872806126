import React, { useMemo } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { groupActivitiesByInterval } from '../../../callLogs/selectors/callLogsSelector';
import { media } from '../utils';

import DateTitledDivider from './DateTitledDivider';
import ActivitiesTimeIntervalSection from './ActivitiesTimeIntervalSection';

const Items = styled.div`
  ${media.md} {
    width: 100%;
    padding: 0;
  }

  padding: 0 40px;
`;

const ActivityListSection = ({
  activities,
  callLogRenderer,
  chatMessageRenderer,
  voicemailRenderer,
  date,
  groupByTime = true,
  session,
}) => {
  const activitiesByInterval = useMemo(() => groupActivitiesByInterval(activities), [activities]);

  return (
    <>
      <DateTitledDivider date={date} id="sectionTitle" />
      <Items>
        {Object.keys(activitiesByInterval).map(time => (
          <ActivitiesTimeIntervalSection
            key={time}
            activities={activitiesByInterval[time]}
            callLogRenderer={callLogRenderer}
            chatMessageRenderer={chatMessageRenderer}
            groupByTime={groupByTime}
            session={session}
            time={moment(time).format('HH[h]mm')}
            voicemailRenderer={voicemailRenderer}
          />
        ))}
      </Items>
    </>
  );
};

export default ActivityListSection;
