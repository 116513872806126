import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Wazo from '@wazo/sdk/lib/simple';
import styled from 'styled-components';
import { useTeamsFx } from '@microsoft/teamsfx-react';
import Typography from '@mui/material/Typography';

import Login, { StyledTextField } from '../../../user/containers/Login';
import TeamsColors from '../common/TeamsColors';
import colors from '../../../main/themes/colors';

const StyledLogin = styled(Login)`
  background-color: ${TeamsColors.light.primary};

  #logo {
    width: 130px;
    margin: 0 auto 30px;
  }

  &.dark {
    background-color: ${TeamsColors.dark.primary};

    .MuiTextField-root {
      * {
        color: #fff;
      }

      fieldset {
        background: rgba(255,255,255, .15);
      }
      
      .Mui-focused fieldset {
        border-color: #777;
      }

      label,
      legend > span {
        color: #999;
      }

      input {
        color: #fff;
    
        &::placeholder {
          color: #555;
        }
      }
    }

    button {
      background: #5068c0;

      &.Mui-disabled {
        background: #444;
      }
    }

    .MuiSwitch-track {
      background: rgba(255,255,255, .5);
    }

    .Mui-checked {
      .MuiSwitch-thumb {
        background: #5068c0;
      }
    }

    .Mui-checked + .MuiSwitch-track {
      background: rgba(100, 120, 200, .7) !important;
    }

    .MuiSwitch-root + p {
      color: #999;
    }
  }
`;

const CreateAccountLink = styled(Typography)`
  display: block;
  padding-top: 20px;
  text-align: center;
  color: ${colors.primary}
`;

const TeamsLogin = ({ t, authenticating, error }) => {
  const [server, setServer] = useState(localStorage.getItem('server'));
  const { themeString } = useTeamsFx();
  const isDark = TeamsColors.isDark(themeString);

  const extra = {
    logo: TeamsColors[isDark ? 'dark' : 'light'].logo,
    actions: {
      performLogin: () => {
        Wazo.Auth.setHost(server);
        localStorage.setItem('server', server);
      },
    },
    fields: (
      <StyledTextField
        autoComplete="user-password"
        disabled={authenticating}
        error={!!error}
        id="server"
        label={t('server')}
        onChange={event => setServer(event.target.value)}
        required
        value={server}
        variant="outlined"
      />
    ),
    footer: (
      <CreateAccountLink dangerouslySetInnerHTML={{ __html: t('createAccount') }} />
    ),
  };

  return <StyledLogin className={isDark ? 'dark' : null} extra={extra} />;
};

const mapStateToProps = state => ({
  authenticating: state.user.authenticating,
  error: state.user.error,
});

export default connect(mapStateToProps)(withTranslation('user')(TeamsLogin));
