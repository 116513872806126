import Collapse from '@mui/material/Collapse';
import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import isDeepEqual from 'lodash/isEqual';

import colors from '../../../main/themes/colors';

import ContactInfoListItem from './ContactInfoListItem';

const ExpandCard = styled.div`
  background-color: ${colors.primaryBackground};
  border-top: none;
  left: ${props => (props.variant === 'right' ? 'none' : '0')};
  right: ${props => (props.variant === 'left' ? 'none' : '0')};
  top: 0;
  z-index: 2000;
  position: absolute;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.13);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 100px;
`;

const ContactDetailsCard = ({
  contact,
  className,
  expanded,
  onCall,
  t,
  variant,
}) => (
  <ExpandCard className={className} data-testid="PhonebookContactCardDetails" expanded={expanded} variant={variant}>
    <Collapse in={expanded}>
      {!!contact.numbers && (
        <div data-testid="PhonebookContactCardDetails-numbers">
          {contact.numbers.map(({ number, label }) => {
            const formattedLabel = !!label && label !== 'phone' ? ` (${t(label).toLowerCase()})` : '';
            return <ContactInfoListItem
              key={`${contact.uuid || contact.sourceId}-${number}-${label}`}
              action={() => onCall(number)}
              icon="phone"
              text={`${number}${formattedLabel}`}
            />;
          })}
        </div>
      )}
    </Collapse>
  </ExpandCard>
);

const isEqual = (props1, props2) => {
  // Don't re-render if not visible
  if (!props1.expanded && !props2.expanded) {
    return true;
  }

  if (props1.expanded !== props2.expanded) {
    return false;
  }

  if (props1.variant !== props2.variant) {
    return false;
  }

  const { contact: contact1 } = props1;
  const { contact: contact2 } = props2;

  if (!contact1.is(contact2)) {
    return false;
  }

  if (!isDeepEqual(contact1.numbers, contact2.numbers)) {
    return false;
  }

  if (!isDeepEqual(contact1.number, contact2.number)) {
    return false;
  }

  return true;
};

export default withTranslation('activities')(React.memo(ContactDetailsCard, isEqual));
