import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { getVoicemailUrl, voicemailFileIsAvailable } from '../../../voicemails/actions/voicemailActions';

import AudioPlayer from './AudioPlayer';
import VoicemailOptions from './VoicemailOptions';

const VoicemailPlayerContainer = styled.div`
  flex-direction: row;
  display: flex;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
`;

const VoicemailPlayer = ({
  hideOptionsButton,
  onAcknowledgeVoicemail,
  optionsVisible,
  showOptions,
  voicemail,
}) => {
  const [playing, setPlaying] = useState(false);
  const [mediaFile, setMediaFile] = useState(null);
  const [hasError, setError] = useState(false);

  const getUrl = async () => {
    const url = await getVoicemailUrl(voicemail);

    setMediaFile(url);
  };

  useEffect(() => {
    getUrl();
  }, []);

  const play = async event => {
    event.preventDefault();
    event.stopPropagation();

    const fileIsAvailable = await voicemailFileIsAvailable(mediaFile);

    if (!fileIsAvailable) {
      setError(true);
      setTimeout(() => setError(false), 5000);
      return;
    }

    onAcknowledgeVoicemail(voicemail);
    setPlaying(true);
  };

  const pause = event => {
    event.preventDefault();
    event.stopPropagation();
    setPlaying(false);
  };

  const voicemailEnded = () => {
    setPlaying(false);
  };

  return (
    <VoicemailPlayerContainer>
      <AudioPlayer
        done={voicemailEnded}
        durationInSeconds={Math.ceil(voicemail.duration / 1000)}
        hasError={hasError}
        pause={pause}
        play={play}
        playing={playing}
        src={mediaFile}
      />
      {showOptions && (
        <VoicemailOptions
          hideOptionsButton={hideOptionsButton}
          visible={optionsVisible}
          voicemail={voicemail}
        />
      )}
    </VoicemailPlayerContainer>
  );
};

export default VoicemailPlayer;
