import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import More from '@mui/icons-material/MoreHoriz';
import { IconButton, ListItem, ListItemText } from '@mui/material';
import Popover from '@mui/material/Popover/index';

import colors from '../../../main/themes/colors';
import { usePrevious } from '../hooks/usePrevious';

const OptionsButton = styled(IconButton)`
  && {
    transition: visibility 0.15s ease-in-out, opacity 0.15s ease-in-out !important;
    margin-right: 10px;
  
    &.hidden {
      visibility: hidden;
      opacity: 0;
    }
  }
`;

const ListText = styled(ListItemText)`
  padding: 0 !important;
`;

const MoreIcon = styled(More)`
  color: ${colors.softBlack} !important;
`;

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const ActivityEventOptions = ({ visible, items, testID, hideOptionsButton }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const prevVisible = usePrevious(visible);

  useEffect(() => {
    if (prevVisible !== visible && !visible) {
      setAnchorEl(null);
    }
  }, [visible]);

  const handleOptionMenuClick = event => {
    event.persist();
    setAnchorEl(event.target);
  };

  const onClose = () => setAnchorEl(null);

  const onMouseLeave = () => {
    setAnchorEl(null);
    hideOptionsButton && hideOptionsButton();
  };

  return (
    <div className="hide-on-mobile" data-testid={testID}>
      <OptionsButton className={visible ? '' : 'hidden'} color="primary" onClick={handleOptionMenuClick} size="small">
        <MoreIcon fontSize="small" />
      </OptionsButton>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        onClose={onClose}
        open={!!anchorEl}
        transformOrigin={transformOrigin}
      >
        <div onMouseLeave={onMouseLeave}>
          {items.map(item => (
            <ListItem
              key={item.label}
              button
              data-testid={item.testID || ''}
              disabled={!item.enabled}
              onClick={item.action}
            >
              <ListText primary={item.label} />
            </ListItem>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default ActivityEventOptions;
