import { getCallDeepLink } from '../../utils';

export const DEEP_LINK_OPEN = 'deepLink/DEEP_LINK_OPEN';
export const DEEP_LINK_CONNECTED = 'deepLink/DEEP_LINK_CONNECTED';
export const DEEP_LINK_DISCONNECTED = 'deepLink/DEEP_LINK_DISCONNECTED';
export const DEEP_LINK_DISPLAY_WARNING = 'deepLink/DEEP_LINK_DISPLAY_WARNING';

export const openCallNumberLink = link => ({
  type: DEEP_LINK_OPEN,
  payload: { link: getCallDeepLink(link) },
});

export const onDeepLinkConnected = () => ({ type: DEEP_LINK_CONNECTED });
export const onDeepLinkDisconnected = () => ({ type: DEEP_LINK_DISCONNECTED });

export const shouldDisplayDeepLinkWarning = display => ({ type: DEEP_LINK_DISPLAY_WARNING, display });
