import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import colors from '../../../main/themes/colors';
import { getDisplayNameFromNumber, findContactByNumber } from '../../../contact/selectors/contactSelector';
import { media } from '../utils';
import WideActivityListItem from '../activities/WideActivityListItem';
import Duration from '../activities/Duration';
import { openCallNumberLink } from '../deepLink/actions/deepLinkActions';

import CallLogIcon from './CallLogIcon';
import CallLogStatus from './CallLogStatus';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
  justify-content: flex-end;

  svg {
    margin-right: 4px;
  }
`;

const CallLabel = styled.div`
  display: flex;
  background-color: ${colors.callTagBackground};
  color: ${colors.callTagText};
  margin-right: 15px;
  height: 26px;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 13px;
  border-radius: 6px;

  ${media.md}{
    margin-right: 0px;
  }
`;

const Status = styled.div`
  white-space: nowrap;
`;

const NumCalls = styled(Typography)`
  && {
    color: ${colors.callTagText};
    white-space: nowrap;
    margin: 0 8px;
  }

  ${media.lg} {
    display: none;
  }
`;

const CallEventItem = ({
  actions,
  deepLinkConnected,
  callLog,
  contact,
  displayName,
  number,
  session,
  t,
}) => {
  const onCall = async (otherNumber, enableVideo) => actions.openCallNumberLink(otherNumber || number, enableVideo);

  return (
    <WideActivityListItem
      callDisabled={!deepLinkConnected}
      centerContent={
        <Container>
          {callLog.cumulativeQuantity > 1 && (
            <NumCalls variant="caption">
              {`${callLog.cumulativeQuantity} ${t('calls').toLowerCase()}`}
            </NumCalls>
          )}

          <CallLabel className="call-state">
            <CallLogIcon callLog={callLog} session={session} size={16} />

            <Status className="hide-on-mobile">
              <CallLogStatus callLog={callLog} id="callLogStatus" session={session} />
            </Status>

            <Duration callLog={callLog} session={session} />
          </CallLabel>
        </Container>
      }
      className="call-event-item"
      contact={contact}
      date={callLog.start}
      displayName={displayName}
      notificationCount={0}
      number={number}
      onCall={onCall}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const session = state.user.session;
  const otherParty = ownProps.callLog.theOtherParty(session);
  const number = otherParty.extension;
  const contact = findContactByNumber(state, number);

  return {
    contact: number && contact,
    displayName: getDisplayNameFromNumber(state, number) || otherParty.name,
    deepLinkConnected: state.deepLink.connected || window.playwright,
    number,
    session,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ openCallNumberLink }, dispatch),
});

export default withTranslation('activities')(connect(mapStateToProps, mapDispatchToProps)(CallEventItem));
