import { app } from '@microsoft/teams-js';
import qs from 'qs';

import { BRIDGE_CONFIG_RETRIEVED, BRIDGE_DISABLE_REGISTER } from '../../main/sagas/bridgeSagas';
import { registerSagaCustomizer } from '../../main/reducers/sagas';
import { registerReducerCustomizer } from '../../main/reducers';
import { sendMessage } from '../../main/utils/integration';

import deepLinkSagas from './deepLink/sagas/deepLinkSagas';
import deepLinkReducer from './deepLink/reducers/deepLinkReducer';
import './styles.css';

const searchParams = qs.parse(window.location.search.slice(1));
const fromLocalStorage = window.localStorage.getItem('server') || window.localStorage.getItem('SERVER_KEY');
const server = process.env.REACT_APP_SERVER || searchParams.server || fromLocalStorage;

if (server && server !== 'null') {
  window.localStorage.setItem('server', server);
}

registerSagaCustomizer(sagas => {
  sagas.push(...deepLinkSagas);
  return sagas;
});

registerReducerCustomizer(reducers => {
  reducers.deepLink = deepLinkReducer;
  return reducers;
});

// Should be after `registerSagaCustomizer` and `registerReducerCustomizer`.
const TeamsApp = require('./TeamsApp').default;

const onMessage = async event => {
  switch (event.data.type) {
    default:
      break;
  }
};

const init = async () => {
  let language = null;
  try {
    await app.initialize();
    const context = await app.getContext();
    language = context?.app?.locale?.substr(0, 2);
  } catch (_) {
    // Nothing to do
  }

  sendMessage(BRIDGE_CONFIG_RETRIEVED, {
    config: {
      server,
      language,
    },
  });

  sendMessage(BRIDGE_DISABLE_REGISTER);

  app.notifySuccess();
};

window.addEventListener('message', onMessage, false);

init();

export default TeamsApp;
