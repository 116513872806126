import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { isFetchingVoicemails, getVoicemailHistory } from '../../../voicemails/selectors/voicemailSelector';
import Images from '../../../main/themes/Images';
import EmptyState from '../common/EmptyState';
import LocalActivityIndicator from '../common/ActivityIndicator';
import ActivityList from '../activities/ActivityList';

import VoicemailEventItem from './VoicemailEventItem';

const EmptyStateIcon = styled.img`
  width: 198px !important;
  height: 40px !important;
  display: flex !important;
`;

export const VoicemailHistory = ({ fetching, t, voicemails, session }) => {
  if (fetching) {
    return <LocalActivityIndicator centered id="spinner" label={t('loadingHistory')} />;
  }

  if (!voicemails.length) {
    return <EmptyState img={<EmptyStateIcon src={Images.voicemailIcon} />}>{t('emptyStateVoicemails')}</EmptyState>;
  }

  return (
    <ActivityList
      activities={voicemails}
      groupByTime={false}
      id="activity-list"
      session={session}
      voicemailRenderer={VoicemailEventItem}
    />
  );
};

const mapStateToProps = state => ({
  voicemails: getVoicemailHistory(state),
  session: state.user.session,
  fetching: isFetchingVoicemails(state),
});

export default connect(mapStateToProps)(withTranslation('activities')(VoicemailHistory));
