import React, { useMemo } from 'react';
import styled from 'styled-components';

import { getActivityDate, groupActivitiesByDay } from '../../../callLogs/selectors/callLogsSelector';
import Scrollbars from '../../../main/components/Scrollbars';

import ActivityListSection from './ActivityListSection';

const NAV_HEIGHT = 48;

const Bars = styled(Scrollbars)`
  max-height: calc(100vh - ${NAV_HEIGHT}px);
`;

const ActivityList = ({
  activities,
  callLogRenderer,
  chatMessageRenderer,
  inverted = false,
  sorted = false,
  groupByTime = true,
  voicemailRenderer,
  session,
  id,
}) => {
  const mappedActivities = useMemo(() => {
    if (sorted) {
      activities.sort((a, b) => getActivityDate(b) - getActivityDate(a));
    }
    if (inverted) {
      activities.sort((a, b) => getActivityDate(a) - getActivityDate(b));
    }
    const dailyActivities = groupActivitiesByDay(activities);

    return Object.keys(dailyActivities).map(date => (
      <ActivityListSection
        key={date}
        activities={dailyActivities[date]}
        callLogRenderer={callLogRenderer}
        chatMessageRenderer={chatMessageRenderer}
        date={getActivityDate(dailyActivities[date][0])}
        groupByTime={groupByTime}
        id={date}
        session={session}
        voicemailRenderer={voicemailRenderer}
      />
    ));
  }, [activities]);

  return <Bars data-testid="activities" id={id}>{mappedActivities}</Bars>;
};

export default ActivityList;
