import React, { memo } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography/Typography';

import colors from '../../../main/themes/colors';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
`;

const Title = styled(({ dense, bold, ...rest }) => <Typography {...rest} />)`
  padding: 0 40px;
  color: ${colors.tertiary} !important;
  font-weight: ${props => (props.bold ? 'bold' : 'initial')} !important;
  font-size: ${props => (props.dense ? '10px' : '11px')} !important;
  letter-spacing: ${props => (props.dense ? '0.16px' : '0.19px')} !important;
  text-transform: uppercase;
`;

const TitledDivider = ({ bold, className, dense, title }) => (
  <Container className={className}>
    <Title bold={bold} dense={dense}>
      {title}
    </Title>
  </Container>
);

export default memo(TitledDivider);
