import { createSelector } from 'reselect';
import { STATE as PROFILE_STATE, LINE_STATE } from '@wazo/sdk/lib/domain/Profile';
import { getDisplayableNumber } from '@wazo/sdk/lib/utils/PhoneNumberUtil';

// Required by getDisplayableNumber but not really used
const defaultCountry = 'FR';

export const PRESENTIAL_STATES = {
  AVAILABLE: 'PRESENTIAL_AVAILABLE',
  AWAY: 'PRESENTIAL_AWAY',
  DND: 'PRESENTIAL_DND',
  BUSY: 'PRESENTIAL_BUSY',
  DISCONNECTED: 'PRESENTIAL_DISCONNECTED',
  NON_WEBRTC: 'PRESENTIAL_NON_WEBRTC',
  NONE: 'PRESENTIAL_NONE',
};

export const PRESENTIAL_COLORS = {
  GREEN: '#88CD78',
  YELLOW: '#ffbb00',
  RED: '#CC0214',
  GRAY: '#ccc',
};

export const getPresentialColor = presentialState => {
  const { AVAILABLE, AWAY, BUSY, DND, NON_WEBRTC, DISCONNECTED, NONE } = PRESENTIAL_STATES;
  const { GREEN, YELLOW, RED, GRAY } = PRESENTIAL_COLORS;

  switch (presentialState) {
    case AVAILABLE:
      return GREEN;
    case AWAY:
      return YELLOW;
    case BUSY:
    case DND:
      return RED;
    case DISCONNECTED:
      return GRAY;
    case NON_WEBRTC:
      return '#fff';
    case NONE:
    default:
      return 'transparent';
  }
};

const getPresentialStateSelector = (state, contact) => {
  const { AVAILABLE, AWAY, BUSY, DND, NON_WEBRTC, DISCONNECTED, NONE } = PRESENTIAL_STATES;
  if (!contact || !contact.isIntern()) {
    return NONE;
  }

  const { lineState, mobile, connected: inboundConnected, state: profileState, lastActivity } = contact;

  const checkState = () => {
    switch (profileState) {
      case PROFILE_STATE.AVAILABLE:
        return AVAILABLE;
      case PROFILE_STATE.AWAY:
      case PROFILE_STATE.UNAVAILABLE:
        return AWAY;
      default:
        return contact.previousPresential || DISCONNECTED;
    }
  };

  if (contact.doNotDisturb) {
    return DND;
  }

  if (lineState === LINE_STATE.PROGRESSING) {
    return contact.previousPresential || BUSY;
  }

  if (lineState === LINE_STATE.TALKING || lineState === LINE_STATE.HOLDING) {
    return BUSY;
  }

  if (lineState === LINE_STATE.AVAILABLE || lineState === LINE_STATE.RINGING) {
    if (!lastActivity) {
      return NON_WEBRTC;
    }

    if (!inboundConnected && !mobile) {
      return NON_WEBRTC;
    }

    return checkState();
  }

  if (mobile) {
    return checkState();
  }

  if (lineState === LINE_STATE.UNAVAILABLE) {
    return DISCONNECTED;
  }

  return contact.previousPresential || DISCONNECTED;
};

export const getPresentialState = createSelector(
  getPresentialStateSelector,
  presentialState => presentialState,
);

const getAllContactSelector = state =>
  [...Object.values(state.contact.callLogContacts), state.contact.ownContact];

export const getAllContacts = createSelector(
  getAllContactSelector,
  contacts => contacts,
);

export const findContactByNumber = (state, number) => {
  if (!number) {
    return null;
  }

  const formattedNumber = getDisplayableNumber(number, defaultCountry);

  return getAllContacts(state).find(c => c?.hasNumber(number) || c?.hasNumber(formattedNumber)
    || getDisplayableNumber(c?.number, defaultCountry) === formattedNumber);
};

export const getDisplayNameFromNumber = (state, number) => {
  const contact = findContactByNumber(state, number);

  return contact ? contact.name : '';
};
