import { darken } from '@mui/material/styles';

import logoLight from '../assets/wazo-logo-color.svg';
import logoDark from '../assets/wazo-logo-white.svg';

const Colors = {
  isDark: theme => theme !== 'default' && theme !== 'light',

  light: {
    logo: logoLight,

    primary: '#f5f5f5', // '#ebebeb'
    primaryDark: '#ebebeb',
    gray: '#ddd',
    grayDark: '#ccc',
    input: {
      back: '#eee',
      front: '#777',
      placeholder: '#ccc',
      button: '#203890',
    },
    button: {
      back: '#ddd',
      digit: '#666',
      desc: '#666',
    },
  },
  dark: {
    logo: logoDark,

    primary: '#1f1f1f',
    primaryDark: '#152B85',
    gray: '#ddd',
    grayDark: '#ccc',
    input: {
      back: '#666',
      front: '#fff',
      placeholder: '#333',
      button: '#4058c0',
    },
    button: {
      back: 'rgba(255, 255, 255, 0.07)',
      digit: '#fff',
      desc: '#bbb',
    },
  },
};

Colors.light.button.hover = darken(Colors.light.button.back, 0.1);
Colors.dark.button.hover = darken(Colors.dark.button.back, 0.1);

export default Colors;
