import * as React from 'react';
import styled from 'styled-components';

import Images from '../../../main/themes/Images';

const companyNames = ['csv', 'csv_ws', 'ldap'];

const Adornment = styled.img`
  margin-right: 5px;
`;

const GoogleAdornment = styled.img`
  margin-right: 3px;
`;

const PersonalAdornment = styled.img`
  margin-right: 3px;
  margin-left: -2px;
`;

export const ContactAdornment = ({ contact, ...rest }) => {
  if (!contact) {
    return null;
  }

  if (contact.backend === 'office365') {
    return (
      <Adornment alt="Outlook icon" data-testid="contact-adornment-office365" src={Images.outlookIcon} {...rest} />
    );
  }

  if (contact.backend === 'google') {
    return (
      <GoogleAdornment alt="Google icon" src={Images.googleIcon} {...rest} data-testid="contact-adornment-google" />
    );
  }

  if (contact.backend === 'personal' || contact.source === 'personal') {
    return (
      <PersonalAdornment
        alt="Personal icon"
        data-testid="contact-adornment-personal"
        src={Images.personalIcon}
        {...rest}
      />
    );
  }

  if (contact.backend === 'conference' || contact.backend === 'meeting') {
    return <Adornment alt="People" data-testid="contact-adornment-conference" src={Images.conferenceIcon} {...rest} />;
  }

  if (companyNames.includes(contact.backend)) {
    return <Adornment alt="Company icon" data-testid="contact-adornment-company" src={Images.companyIcon} {...rest} />;
  }

  return null;
};

const isEqual = (props1, props2) =>
  !!props1.contact &&
  !!props2.contact &&
  props1.contact.backend === props2.contact.source &&
  props1.contact.backend === props2.contact.source;

export default React.memo(ContactAdornment, isEqual);
