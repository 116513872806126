import React from 'react';
import styled from 'styled-components';
import { Card, CardContent, Typography } from '@mui/material';
import moment from 'moment';

import ContactCard from '../contacts/ContactCard';
import ContactDetailsCard from '../contacts/ContactDetailsCard';
import { media } from '../utils';

import CallButton from './CallButton';

const BaseRow = styled.div`
  display: flex;
  position: relative;

  ${media.md} {
    flex: 0 1 200px;
  }
`;

const StyledCard = styled(({ expanded, ...rest }) => <Card {...rest} />)`
  && {
    background: transparent;
    height: 68px;
    display: flex;
    width: 100%;
    z-index: ${props => (props.expanded ? '3000' : '0')};
    transition: z-index ${props => (props.expanded ? '0' : '2')}s, box-shadow 0.25s;
    border-radius: 0;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    margin-top: -1px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.12);
  }
`;

const Content = styled(CardContent)`
  && {
    align-items: center;
    display: flex;
    flex: 1;
    padding-bottom: 0;
    padding-top: 0;
    justify-content: space-between;
    overflow: hidden;
    height: 100%;
  
    ${media.md} {
      padding: 0 10px;
    }
  }
`;

const FormattedDate = styled(Typography)`
  && {
    margin-left: 5px;
    margin-right: 15px;
    text-align: right;
    
    ${media.md}{
      margin-right: 0;
      font-size: 12px;
    }
  }
`;

const LeftZone = styled.div`
  display: flex;
  align-items: center;
  width: 50%;

  ${media.md} {
    width: 70%;
  }
`;

const CenterZone = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;

  ${media.md} {
    flex: 0;
  }
`;

const RightZone = styled.div`
  display: flex;
  width: max-content;
  height: 100%;
  align-items: center;
  justify-content: flex-end;

  ${media.md} {
    flex: 0 40px;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const ContactDetails = styled(ContactDetailsCard)`
  width: 30%;
  margin-left: 40px !important;
  margin-right: 40px !important;
  margin-top: 68px !important;
`;

const WideActivityListItem = ({
  callDisabled = false,
  contact,
  date,
  displayName,
  number,
  onCall,
  centerContent,
  showOptionButton,
  hideOptionButton,
  className,
}) => {
  if (!contact && !displayName && !number) {
    return null;
  }

  return (
    <BaseRow className={className}>
      <StyledCard onMouseEnter={showOptionButton} onMouseLeave={hideOptionButton}>
        <Content data-testid="wideActivityListItem">
          <LeftZone data-testid="wideActivityListItem-leftZone">
            <ContactCard contact={contact} name={displayName} number={number} />
          </LeftZone>

          <CenterZone>{centerContent}</CenterZone>

          <RightZone>
            <FormattedDate className="date">{moment(date).format('HH[h]mm')}</FormattedDate>

            <Actions>
              <CallButton bgcolor="#eee" contact={contact} disabled={callDisabled} number={number} onCall={onCall} />
            </Actions>
          </RightZone>
        </Content>
      </StyledCard>
      {!!contact && (
        <ContactDetails
          contact={contact}
          onCall={onCall}
          variant="right"
        />
      )}
    </BaseRow>
  );
};

export default React.memo(WideActivityListItem);
