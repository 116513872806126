import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import PhoneIcon from '@mui/icons-material/Phone';
import Email from '@mui/icons-material/Email';

const Root = styled(ListItem)`
  min-width: 0;
`;

const ActionButton = styled.div`
  padding: 6px !important;
  height: fit-content;
  display: flex;
`;

const Text = styled(ListItemText)`
  margin-left: 20px;
  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px !important;
  }
`;

const getIcon = (icon) => {
  switch (icon) {
    case 'email':
      return <Email color="primary" fontSize="small" />;
    case 'phone':
      return <PhoneIcon color="primary" fontSize="small" />;
    default:
      return null;
  }
};

const textTypography = {
  variant: 'body2',
};

export const ContactInfoListItem = ({ action, icon, text }) => (
  <Root
    button
    onClick={event => {
      event.preventDefault();
      event.stopPropagation();
      !!action && action();
    }}
  >
    <ListItemIcon>
      <ActionButton>{getIcon(icon)}</ActionButton>
    </ListItemIcon>
    <Text primary={text} primaryTypographyProps={textTypography} />
  </Root>
);

export default React.memo(ContactInfoListItem);
