export const getSubText = (contact, number, name, t) => {
  if (contact && contact.isIntern()) {
    const contactNumber = contact.number ||
      (contact.numbers && contact.numbers.length ? contact.numbers[0].number : contact.number);

    if (contactNumber) {
      return `${contactNumber}${contact.personalStatus ? ` - ${contact.personalStatus}` : ''}`;
    }

    return contact.personalStatus;
  }

  const numberAndNameIdentical = !!number && !!name && number === name;

  if (numberAndNameIdentical) {
    return null;
  }

  if (!contact) {
    if (!!name) {
      return number;
    }

    return null;
  }

  const contactHasName = !!contact.name || !!name;

  if (!contactHasName) {
    return null;
  }

  if (contact.backend === 'meeting') {
    return t('meeting');
  }

  const canShowContactMainNumber = !contact.isIntern() && !!contact.numbers && contact.numbers.length > 0;

  if (canShowContactMainNumber) {
    return contact.number || contact.numbers[0].number;
  }

  const canShowContactMainEmail = !contact.isIntern() && !!contact.emails && contact.emails.length > 0;

  if (canShowContactMainEmail) {
    return contact.emails[0].email;
  }

  return !contact.isIntern() && number;
};
