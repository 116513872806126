export const getCallDeepLink = number => `wazo://calls/${number}?direct&context=teams`;

const sizes = {
  sm: '320px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

// Breakpoints based on your configuration
export const media = {
  sm: `@media (max-width: ${sizes.sm})`,
  md: `@media (max-width: ${sizes.md})`,
  lg: `@media (max-width: ${sizes.lg})`,
  xl: `@media (max-width: ${sizes.xl})`,
  max: `${sizes.xlg}`,
};
