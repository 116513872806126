import React from 'react';
import { connect, Provider } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';
import { Navigate, Route, Routes } from 'react-router-dom';

import getMuiTheme from '../../main/themes/muiTheme';
import wazoTheme from '../../main/themes';
import configureStore from '../../main/reducers/configureStore';
import WazoRouter from '../../main/router/WazoRouter';
import history from '../../main/router/history';
import MainContainer from '../../main/containers/MainContainer';
import ErrorMessage from '../../main/containers/ErrorMessage';

import TeamsLogin from './user/TeamsLogin';
import Home from './home/Home';
import './TeamsApp.css';

window.store = configureStore();

const UnConnectedRouter = ({ authenticated }) => (
  <WazoRouter history={history}>
    {!authenticated ? (
      <Routes>
        <Route element={<TeamsLogin />} index path="/" />
        <Route element={<Navigate replace to="/" />} path="*" />
      </Routes>
    ) : (
      <MainContainer>
        <Routes>
          <Route element={<Home />} exact path="/dialer" />
        </Routes>
        <ErrorMessage />
      </MainContainer>
    )}
  </WazoRouter>
);

const ConnectedRouter = connect(state => ({
  authenticated: state.user.authenticated,
}))(React.memo(UnConnectedRouter));

const TeamsApp = () => (
  <MuiThemeProvider theme={getMuiTheme({})}>
    <ThemeProvider theme={wazoTheme}>
      <Provider store={window.store}>
        <ConnectedRouter />
      </Provider>
    </ThemeProvider>
  </MuiThemeProvider>
);

export default React.memo(TeamsApp);
