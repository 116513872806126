import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import styled from 'styled-components';
import Slide from '@mui/material/Slide';
import { withTranslation } from 'react-i18next';

const Warning = styled(Snackbar)`
  & > div[role="alert"] {
    background-color: #ad4d19;
  }
`;

const SlideTransition = (props) => <Slide {...props} direction="up" />;

const DeepLinkNotConnected = ({ shouldDisplayWarning, t }) => (
  <Warning
    className="hide-on-mobile"
    direction="up"
    message={t('notConnectedToWDA')}
    open={shouldDisplayWarning}
    TransitionComponent={SlideTransition}
    variant="warning"
  />
);

const mapStateToProps = state => ({
  shouldDisplayWarning: state.deepLink.shouldDisplayWarning,
});

export default connect(mapStateToProps)(withTranslation('user')(DeepLinkNotConnected));
