import React from 'react';
import { withTranslation } from 'react-i18next';

const CallLogStatus = ({ callLog, session, t }) => {
  if (callLog.isAMissedOutgoingCall(session)) {
    return t('missedOutgoingCall');
  }

  // Handle missed calls from a SDA (eg: it doesn't have the extension but is incoming and not answered).
  if (callLog.isADeclinedCall(session) || (callLog.isIncoming(session) && !callLog.isAnswered())) {
    return t('missedCall');
  }

  if (callLog.isOutgoing(session)) {
    return t('outgoingCall');
  }

  if (callLog.isIncoming(session)) {
    return t('incomingCallLog');
  }

  return t('unknown');
};

export default withTranslation('activities')(React.memo(CallLogStatus));
