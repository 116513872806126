import React from 'react';
import styled from 'styled-components';
import { IconButton, Tooltip } from '@mui/material';
import { Logout as Icon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { logout } from '../../../user/actions/userActions';

const ButtonWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;

  button {
    svg {
      color: #ccc;
      font-size: 17px;
    }
  }
`;

const Logout = () => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(logout());
  const { t } = useTranslation('user');

  return (
    <ButtonWrapper>
      <Tooltip placement="bottom" title={t('logout')}>
        <IconButton id="logout-button" onClick={onClick}>
          <Icon color="inherit" fontSize="small" />
        </IconButton>
      </Tooltip>
    </ButtonWrapper>
  );
};

export default Logout;
