import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

import colors from '../../../main/themes/colors';
import { media } from '../utils';

// ContactName is sometimes included in `EmptyState` which has a <p> container. So it can't be a div.
const Container = styled.span`
  min-width: 0;
  display: flex;
  align-items: center;

  ${media.md} {
    font-size: 12px;
  }
`;

// ContactName is sometimes included in `EmptyState` which has a <p> container. So it can't be a p.
const Text = styled(({ reversed, ...rest }) => <Typography {...rest} component="span" />)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0 !important;
`;

const NormalText = styled(Text)`
  color: ${colors.primaryText} !important;
`;

const AdornmentText = styled(Text)`
  color: ${colors.primaryText} !important;
  & > * {
    font-weight: normal !important;
  }
`;

const getContactName = contact => {
  if (contact.name) {
    return <strong>{contact.name}</strong>;
  }

  let content = '';

  if (contact.numbers && contact.numbers.length > 0 && contact.numbers[0].number) {
    content = contact.numbers[0].number;
  }

  if (contact.number) {
    content = contact.number;
  }

  if (contact.emails && contact.emails.length > 0 && contact.emails[0].email) {
    content = contact.emails[0].email;
  }

  if (contact.email) {
    content = contact.email;
  }

  return <strong>{content}</strong>;
};

const ContactName = ({ contact, hasAdornment = false, reversed, ...rest }) => {
  const name = getContactName(contact);

  return (
    <Container data-testid="contact-name" {...rest}>
      {hasAdornment ? (
        <AdornmentText reversed={reversed}>{name}</AdornmentText>
      ) : (
        <NormalText reversed={reversed}>{name}</NormalText>
      )}
    </Container>
  );
};

export default ContactName;
