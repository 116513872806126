import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import Contact from '@wazo/sdk/lib/domain/Contact';

import colors from '../../../main/themes/colors';
import { getSubText } from '../../../contact/domain/ContactInfo';
import { media } from '../utils';

import ContactAdornment from './ContactAdornment';
import Avatar from './Avatar';
import ContactName from './ContactName';

const Root = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.primary};
  justify-content: center;
  margin-left: 15px;
  min-width: 0;
  flex: 1;
  overflow: hidden;

  ${media.md}{
    margin-left: 10px;
  }
`;

const ContactSubText = styled(Typography)`
  && {
    line-height: 1.3em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;

    ${media.md} {
      font-size: 12px;
    }
  }
`;

const Number = styled(Typography)`
  && {
    color: ${props => (props.reversed ? colors.contrastingText : colors.black)};
  }
`;

const Secondary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:first-child img {
    max-height: 24px;
  }

  &:nth-child(2) img {
    max-height: 18px;
  }
`;

const TalkingIcon = styled(RecordVoiceOverIcon)`
  && {
    color: ${colors.secondaryBackground};
    padding-left: 5px;
    width: 20px;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
`;

const Right = styled.div``;

export function ContactCard({
  onClick,
  contact,
  name,
  number,
  reversed = false,
  t,
  talking,
  avatarSize,
  right,
  isMe,
}) {
  if (!contact && !name && !number) {
    return <p data-testid="contact-card-error">{t('noContactInformationToDisplay')}</p>;
  }

  const subtext = getSubText(contact, number, name, t);

  const adornment = <ContactAdornment contact={contact} />;
  const tempContact = new Contact({ name });

  return (
    <Root data-testid="contact-card" onClick={onClick}>
      <Left>
        <Avatar
          contact={contact || tempContact}
          displayName={contact && contact.name}
          size={avatarSize}
        />
        <TextContainer>
          <Secondary>
            {!subtext && adornment}
            {(!!contact?.name) || !!name ? (
              <ContactName
                contact={contact || tempContact}
                data-testid="contact-card-name"
                hasAdornment={!!adornment && !subtext}
                isMe={isMe}
                reversed={reversed}
              />
            ) : (
              <Number data-testid="contact-card-number">{number || contact?.number}</Number>
            )}
            {talking && <TalkingIcon />}
          </Secondary>
          {subtext ? (
            <Secondary>
              {subtext && adornment}
              {subtext && (
                <ContactSubText data-testid="contact-card-sub-text" variant="body2">
                  {subtext}
                </ContactSubText>
              )}
            </Secondary>
          ) : null}
        </TextContainer>
      </Left>

      {right && (
        <Right className="right">
          {right}
        </Right>
      )}
    </Root>
  );
}

export default withTranslation('activities')(React.memo((ContactCard)));
