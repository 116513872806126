import React, { useMemo, memo } from 'react';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';

import TitledDivider from './TitledDivider';
import { ActivityStatus } from './domain';

const getActivitySide = (previousActivity, session) => {
  if (previousActivity.type === 'CallLog' && previousActivity.isIncoming(session)) {
    return ActivityStatus.RECEIVED;
  }

  if (previousActivity.type === 'Voicemail') {
    return ActivityStatus.RECEIVED;
  }

  return ActivityStatus.SENT;
};

export const ActivitiesTimeIntervalSection = ({
  activities,
  callLogRenderer,
  voicemailRenderer,
  time,
  groupByTime = true,
  session,
}) => {
  const mappedActivities = useMemo(() => activities.map((activity, index) => {
    let previousSide = null;
    let followingSide = null;

    if (index > 0) {
      const previousActivity = activities[index - 1];
      previousSide = getActivitySide(previousActivity, session);
    }

    if (index < activities.length - 1) {
      const followingActivity = activities[index + 1];
      followingSide = getActivitySide(followingActivity, session);
    }

    if (activity.type === 'CallLog') {
      const CallLogComponent = callLogRenderer;
      return (
        <CallLogComponent
          key={activity.id}
          callLog={activity}
          followingSide={followingSide}
          id={activity.id}
          previousSide={previousSide}
        />
      );
    }

    if (activity.type === 'Voicemail') {
      const VoiceMailComponent = voicemailRenderer;
      return (
        <VoiceMailComponent
          key={activity.id}
          followingSide={followingSide}
          id={activity.id}
          previousSide={previousSide}
          voicemail={activity}
        />
      );
    }
    return <React.Fragment key={activity.id} />;
  }), [activities]);

  return (
    <>
      {groupByTime && (
        <Grid item xs={12}>
          <TitledDivider bold dense divider={false} id="title" title={time} />
        </Grid>
      )}

      <div>
        {mappedActivities}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  session: state.user.session,
});

export default connect(mapStateToProps)(memo(ActivitiesTimeIntervalSection));
