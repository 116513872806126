import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';

import { findContactByNumber, getDisplayNameFromNumber } from '../../../contact/selectors/contactSelector';
import { acknowledgeVoicemail, deleteVoicemail } from '../../../voicemails/actions/voicemailActions';
import WideActivityListItem from '../activities/WideActivityListItem';
import { openCallNumberLink } from '../deepLink/actions/deepLinkActions';

import VoicemailPlayer from './VoicemailPlayer';

const VoicemailWrapper = styled.div`
  display: flex;
  flex-grow: 0 40%;
`;

const VoicemailEventItem = ({ actions, contact, displayName, voicemail, number, t, deepLinkConnected }) => {
  const [showButton, setButtonShown] = useState(false);

  const hideOptionButton = () => setButtonShown(false);
  const showOptionButton = () => setButtonShown(true);
  const onCall = async (otherNumber, enableVideo) =>
    action.openCallNumberLink(otherNumber || number, enableVideo);

  const player = (
    <VoicemailWrapper>
      <VoicemailPlayer
        hideOptionsButton={hideOptionButton}
        onAcknowledgeVoicemail={actions.acknowledgeVoicemail}
        onDeleteVoicemail={actions.deleteVoicemail}
        optionsVisible={showButton}
        showOptions
        visible={showButton}
        voicemail={voicemail}
      />
    </VoicemailWrapper>
  );

  return (
    <WideActivityListItem
      alignSummary="left"
      callDisabled={!deepLinkConnected}
      centerContent={player}
      className="voicemail-event-item"
      contact={contact}
      date={voicemail.date}
      displayName={displayName}
      hideOptionButton={hideOptionButton}
      number={voicemail.caller.number}
      onCall={onCall}
      showOptionButton={showOptionButton}
      t={t}
      testID="voicemail-item"
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const { number } = ownProps.voicemail.caller;
  const contact = number && findContactByNumber(state, number);

  return {
    contact,
    displayName: getDisplayNameFromNumber(state, number),
    deepLinkConnected: state.deepLink.connected || window.playwright,
    number,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ acknowledgeVoicemail, deleteVoicemail, openCallNumberLink }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('activities')(VoicemailEventItem));
