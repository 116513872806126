/* eslint-disable react/jsx-sort-props */
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import Play from '@mui/icons-material/PlayArrow';
import Pause from '@mui/icons-material/Pause';
import { IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import moment from 'moment';

import colors from '../../../main/themes/colors';
import { usePrevious } from '../hooks/usePrevious';
import { media } from '../utils';

import Timer from './Timer';

const PlayerContainer = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 25px;
  position: relative;
  margin-left: 10px;
  cursor: default;
`;

const VoicemailSlider = styled(Slider)`
  && {
    align-items: center;
    width: 125px;
    color: ${props => props.disabled ? colors.disabled : colors.primary};
    margin-left: 25px;
    margin-right: 25px;

    &.Mui-disabled .MuiSlider-thumb {
      background-color: ${colors.disabled};
    }
    
    ${media.md} {
      width: 40px;
    }
  }
`;

const ActionButton = styled(({ playing, ...rest }) => <IconButton {...rest} size="large" />)`
  && {
    position: absolute;
    padding: 0;
    border: 2px solid ${colors.primary};
    background-color: ${props => props.playing && colors.primary};
    color: ${props => (props.playing ? colors.white : colors.primary)};
    z-index: 1000;
    left: -12px;

    &.Mui-disabled {
      border: 2px solid ${colors.disabled};
      color: ${colors.disabled};
    }
  }
`;

const TimeLeft = styled.div`
  border-radius: 50px;
  border: 2px solid ${colors.primary};
  height: 23px;
  padding: 0 5px;
  margin-left: -20px;
  z-index: 1000;
  color: ${colors.primary};

  &[disabled] {
    color: ${colors.disabled};
    border-color: ${colors.disabled};
  }
`;

const TimeLeftText = styled(Typography)`
  && {
    font-size: 11px;
    line-height: 11px;
    margin: 5px 0 0;
    letter-spacing: 0.16px;
  }
`;

const AudioPlayer = ({ durationInSeconds, pause, play, playing, hasError, src, done }) => {
  const audioElement = useRef(null);
  const [progressInSeconds, setProgressInSeconds] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [mediaFile, setMediaFile] = useState('');
  const prevPlaying = usePrevious(playing);
  const prevMediaFile = usePrevious(mediaFile);

  useEffect(() => {
    if (prevPlaying && !playing) {
      audioElement.current.pause();
    }

    if (mediaFile && !prevPlaying && playing) {
      audioElement.current.play();
    }

    if (mediaFile && playing && audioElement.current.paused) {
      audioElement.current.play();
    }

    if (!playing && !audioElement.current.paused) {
      audioElement.current.pause();
    }

    if (mediaFile && mediaFile !== prevMediaFile) {
      audioElement.current.play();
    }

    if (playing && !loaded) {
      setLoaded(true);

      try {
        fetch(src).then(response => {
          if (!response.ok) {
            return;
          }
          response.blob().then(blob => {
            const fr = new FileReader();
            fr.readAsDataURL(blob);
            fr.onloadend = () => {
              setMediaFile(fr.result);
            };
          });
        });
      } catch (e) {
        // ignore
      }
    }
  }, [playing, prevPlaying, loaded, mediaFile, audioElement.current?.paused]);

  useEffect(() => {
    if (audioElement.current.ended || progressInSeconds >= durationInSeconds) {
      setProgressInSeconds(0);
      done();
      audioElement.current.pause();
    }
  }, [progressInSeconds]);

  const stopEvent = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const updateProgress = (event, value) => {
    audioElement.current.currentTime = value;
    setProgressInSeconds(value);
  };

  const updateElapsedTime = () => {
    setProgressInSeconds((prev) => prev + 1);
  };

  const onClick = playing ? pause : play;
  const disabled = hasError;

  return (
    <PlayerContainer data-testid={playing ? 'audio-player-playing' : 'audio-player-stopped'} onClick={disabled ? stopEvent : null} hasError={hasError}>
      {playing && <Timer callback={updateElapsedTime} interval={1000} />}

      {/* eslint-disable-next-line */}
      <audio data-testid="audio-player-element" preload="none" ref={audioElement} src={mediaFile} />

      <ActionButton disabled={disabled} className="play-audio" data-testid="audio-player-play" onClick={onClick} playing={playing}>
        {playing ? <Pause fontSize="small" /> : <Play fontSize="small" />}
      </ActionButton>

      <VoicemailSlider
        disabled={disabled}
        max={durationInSeconds}
        min={0}
        onChange={updateProgress}
        onClick={stopEvent}
        step={1}
        size="small"
        value={progressInSeconds}
      />
      <TimeLeft disabled={disabled}>
        <TimeLeftText color="inherit" variant="body2">
          {moment(String(Math.abs(durationInSeconds * 1000 - progressInSeconds * 1000)), 'x').format('mm:ss')}
        </TimeLeftText>
      </TimeLeft>
    </PlayerContainer>
  );
};

export default AudioPlayer;
