import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';

import colors from '../../../main/themes/colors';

const BaseButton = styled(({ show, ...rest }) => <IconButton {...rest} size="large" />)`
  && {
    background: ${props => props['data-bgcolor'] || '#fff'};
    color: ${props => (props.disabled ? colors.disabled : colors.primary)};
    padding: 0;
    height: 32px;
    width: 32px;
  }
`;

export const CallButton = ({ contact, disabled, bgcolor, number: rawNumber, onCall }) => {
  const number = contact?.number || rawNumber || (contact?.numbers?.length ? contact.numbers[0].number : null);

  return (
    <BaseButton
      className="hide-on-mobile"
      data-bgcolor={bgcolor}
      data-testid="audio-call"
      disabled={disabled}
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        onCall(number);
      }}
    >
      <CallIcon fontSize="small" />
    </BaseButton>
  );
};

export default CallButton;
