import { createSelector } from 'reselect';
import moment from 'moment';

export const ActivityStatus = {
  RECEIVED: 'RECEIVED',
  MISSED: 'MISSED',
  SENT: 'SENT',
  UNKNOWN: 'UNKNOWN',
};

export const getActivityStatusFromCallLog = (callLog, session) => {
  if (callLog.isOutgoing(session)) {
    return ActivityStatus.SENT;
  }

  return ActivityStatus.RECEIVED;
};

export const getCallLogDuration = (callLog, session) =>
  getActivityStatusFromCallLog(callLog, session) !== ActivityStatus.MISSED ? callLog.duration : undefined;

const logsSelector = state => state.callLogs.callLogs;

export const getActivityDate = activity => activity.start || activity.date;

const isBetween = (sectionDate, activityDate, lastActivityForSection) => {
  const activityInValidSection = moment(sectionDate).isBefore(activityDate, 'minutes');

  if (activityInValidSection) {
    const sectionLimit = moment(getActivityDate(lastActivityForSection)).add(5, 'minutes');
    if (!!lastActivityForSection && activityDate.isBefore(sectionLimit, 'minutes')) {
      return true;
    }
  }

  return moment(sectionDate).isBetween(
    moment(activityDate).subtract(5, 'minutes'),
    moment(activityDate).add(5, 'minutes'),
    'minutes',
    '[]',
  );
};

export const groupActivitiesByDay = activities => activities.reduce((acc, activity) => {
  const date = moment(getActivityDate(activity)).format('YYYY-MM-DD');
  if (!acc[date]) {
    acc[date] = [activity];
  } else {
    acc[date].push(activity);
  }
  return acc;
}, {});

export const groupActivitiesByInterval = activities => activities.reduce((acc, activity) => {
  const activityDate = moment(getActivityDate(activity));
  const activityFitsInExistingSection = Object.keys(acc).some(sectionDate =>
    isBetween(sectionDate, activityDate, acc[sectionDate].slice(-1)[0]));

  if (!activityFitsInExistingSection) {
    const key = activityDate.toISOString();
    acc[key] = [activity];
  } else {
    const appropriateSection = Object.keys(acc).find(sectionDate =>
      isBetween(sectionDate, activityDate, acc[sectionDate].slice(-1)[0]));

    if (!!appropriateSection) {
      acc[appropriateSection].push(activity);
    }
  }
  return acc;
}, {});

export const getCallLogs = createSelector(
  logsSelector,
  logs => logs,
);

const getCallHistory = createSelector(
  state => getCallLogs(state),
  callLogs => callLogs.sort((a, b) => getActivityDate(b) - getActivityDate(a)),
);

export const getCumulativeCallHistory = createSelector(
  getCallHistory,
  state => state.user.session,
  (callLogs, session) => callLogs.reduce((acc, callLog) => {
    const logDate = moment(callLog.start).format('YYYY-MM-DD');
    const contactExtension = callLog.theOtherParty(session).extension;
    const alreadyStoredData = acc.find(e => e.extension === contactExtension && e.date === logDate);

    if (alreadyStoredData) {
      return acc.map(storedData => {
        if (storedData === alreadyStoredData) {
          return { ...storedData, quantity: storedData.quantity + 1 };
        }
        return storedData;
      });
    }

    return acc.concat({ extension: contactExtension, date: logDate, quantity: 1, callLog });
  }, []),
);
