import { DEEP_LINK_CONNECTED, DEEP_LINK_DISCONNECTED, DEEP_LINK_DISPLAY_WARNING } from '../actions/deepLinkActions';

const initialState = {
  connected: false,
  shouldDisplayWarning: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DEEP_LINK_CONNECTED:
      return { ...state, connected: true };

    case DEEP_LINK_DISCONNECTED:
      return { ...state, connected: false };

    case DEEP_LINK_DISPLAY_WARNING:
      return { ...state, shouldDisplayWarning: action.display };

    default:
      return state;
  }
}
