import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import CallIcon from '@mui/icons-material/Call';
import { IconButton } from '@mui/material';
import { useTeamsFx } from '@microsoft/teamsfx-react';
import { bindActionCreators } from 'redux';

import Dialer from '../../../dialer/containers/Dialer';
import colors from '../../../main/themes/colors';
import Activities from '../activities/Activities';
import TeamsColors from '../common/TeamsColors';
import Colors from '../common/TeamsColors';
import { openCallNumberLink } from '../deepLink/actions/deepLinkActions';

import DeepLinkNotConnected from './DeepLinkNotConnected';

const BUTTON_PADDING = 4;

const Container = styled.div`
  background-color: white;
  display: flex;
  height: 100%;
`;

const DialerWrapper = styled.div`
  width: 320px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${TeamsColors.light.primary};
  position: relative;

  .hasContent {
    background: ${TeamsColors.light.primary};
    
    li > div {
      padding-left: 20px;
      border-color: #eee;
      
      button {
        background-color: #e6e6e6;
      }
    }
  }

  .keypad-number-container {
    border-radius: 8px;
    background-color: ${TeamsColors.light.input.back};
    
    input{
      font-family: "Segoe UI", sans-serif;
      font-size: 18px;
      color: ${TeamsColors.light.input.front};
      
      &::placeholder {
        font-size: 18px;
        color: ${TeamsColors.light.input.placeholder};
      }
    }

    button {
      svg {
        fill: ${TeamsColors.light.input.button};
      }

      &.keypad-search {
        background: ${TeamsColors.light.input.button};
        svg {
          fill: ${TeamsColors.light.input.back};
        }
      }
    }
  }

  #dialer-keys {
    margin: 0 -${BUTTON_PADDING}px;

    button {
      border: none;
      color: ${TeamsColors.light.button.front};
      background-color: ${TeamsColors.light.button.back};
      border-radius: 8px;
      margin: ${BUTTON_PADDING}px;
      flex: 1 calc(33% - ${BUTTON_PADDING * 2}px);
      
      &:hover {
        background-color: ${TeamsColors.light.button.hover};
      }
      
      .digit {
        font-size: 24px;
        color: ${TeamsColors.light.button.digit};
      }

      .desc {
        color: ${TeamsColors.light.button.desc};
        font-size: 11px;
      }
    }
  }

  .dark > & {
    background-color: ${TeamsColors.dark.primary};

    .keypad-number-container.active {
      border-bottom: none;
    }
    
    .hasContent {
      background: ${TeamsColors.dark.primary};
      color: #fff;

      li {
        .MuiListItemText-secondary {
          color: rgba(255, 255, 255, 0.4);
        }
        
        .MuiListItem-root {
          border-color: rgba(255, 255, 255, .1);
        }
  
        button {
          background-color: #444;
  
          svg {
            fill: #999;
          }

          &:hover {
            background-color: ${colors.call};
            svg {
              fill: #fff;
            }
          }
        }
      }
    }

    .keypad-number-container {
      background-color: ${TeamsColors.dark.input.back};
      
      input{
        color: ${TeamsColors.dark.input.front};

        &::placeholder {
          color: ${TeamsColors.dark.input.placeholder};
        }
      }

      button {
        svg {
          fill: ${TeamsColors.dark.input.front};
        }

        &.keypad-search {
          background: ${TeamsColors.dark.input.front};
          svg {
            fill: ${TeamsColors.dark.input.back};
          }
        }
      }
    }

    #dialer-keys {
      button {
        background-color: ${TeamsColors.dark.button.back};

        &:hover {
          background-color: ${TeamsColors.dark.button.hover};
        }

        .digit {
          color: ${TeamsColors.dark.button.digit};
        }

        .desc {
          color: ${TeamsColors.dark.button.desc};
        }
      }
    }
  }
`;

const Right = styled.div`
  flex: 2;
  overflow: hidden;

  #logout-button {
    svg {
      color: #888;
    }
  }

  #higher-activities{
    #tabs {
      button {
        font-size: 14px;
        text-transform: none;

        &.Mui-selected {
          font-weight: 600;
        }
      }
    }

    strong {
      font-weight: 600;
      color: #333;
    }
  }

  .dark & {
    background: #171717;
    color: #fff;

    #logout-button {
      svg {
        color: #9d9d9d;
      }
    }

    #higher-activities{
      #tabs {
        box-shadow: 0 3px 6px rgba(0,0,0,.3);

        button {
          color: #bbb;

          &.Mui-selected {
            color: ${Colors.dark.input.button};
          }
        }
        
        .MuiTabs-indicator {
          background-color: ${Colors.dark.input.button};
        }
      }

      strong {
        color: #eee;
      }
    }

    #activity-list {
      button {
        background-color: #444;

        svg {
          fill: #999;
        }

        &:hover {
          background-color: ${colors.call};
          svg {
            fill: #fff;
          }
        }
      }
    }
    
    .avatar {
      background-color: ${Colors.dark.input.button};
    }

    .voicemail-event-item,
    .call-event-item {
      > .MuiPaper-root {
        border-color: #333;
      }

      .MuiTypography-root {
        color: #aaa;
        strong {
          color: #fff;
          font-weight: 600;
        }
      }

      .call-state {
        background: ${Colors.dark.button.back}
      }

      .divider {
        border-color: ${Colors.dark.primary};
      }
    }
  }
`;

const CallButton = styled(IconButton)`
  && {
    margin-top: 20px;
    background: ${colors.greenButton};
    color: #fff;
    width: 68px;
    height: 68px;

    &:hover {
      background-color: ${colors.greenButtonHover};
    }

    .in-call & {
      pointer-events: none;
    }

    svg {
      font-size: 34px;
    }
  }
`;

const Home = ({ keypadNumber, inCall, actions, deepLinkConnected }) => {
  const { themeString } = useTeamsFx();

  const isDark = TeamsColors.isDark(themeString);

  return (
    <Container className={isDark ? 'dark' : null}>
      <Left className={`${inCall ? 'in-call' : null} hide-on-mobile`}>
        <DialerWrapper>
          <Dialer customCallMethod={number => deepLinkConnected && actions.openCallNumberLink(number)} />
          <CallButton disabled={!deepLinkConnected} onClick={() => actions.openCallNumberLink(keypadNumber)}>
            <CallIcon color="inherit" />
          </CallButton>
        </DialerWrapper>
      </Left>

      <Right>
        <Activities />
      </Right>

      <DeepLinkNotConnected />
    </Container>
  );
};

const mapStateToProps = state => ({
  keypadNumber: state.dialer.keypadNumber,
  inCall: state.call.ringing || state.call.inCall,
  deepLinkConnected: state.deepLink.connected || window.playwright,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ openCallNumberLink }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
