import React from 'react';
import { CallMade, CallReceived } from '@mui/icons-material';
import styled from 'styled-components';

import colors from '../../../main/themes/colors';

const OutgoingSuccessIcon = styled(CallMade)`
  color: ${colors.call} !important;
`;

const OutgoingFailIcon = styled(CallMade)`
  color: ${colors.error} !important;
`;

const IncomingSuccessIcon = styled(CallReceived)`
  color: ${colors.call} !important;
  transform: rotate(-90deg);
`;

const IncomingFailIcon = styled(CallReceived)`
  color: ${colors.red} !important;
  transform: rotate(-90deg);
`;

const CallLogIcon = ({ callLog, session, size }) => {
  if (callLog.isOutgoing(session)) {
    if (callLog.isAnswered()) {
      return (
        <OutgoingSuccessIcon
          data-testid="call-log-icon-call-made"
          style={{
            height: `${size}px`,
            width: `${size}px`,
          }}
        />
      );
    }

    if (!callLog.isAnswered()) {
      return (
        <OutgoingFailIcon
          data-testid="call-log-icon-missed-outgoing-call"
          style={{
            height: `${size}px`,
            width: `${size}px`,
          }}
        />
      );
    }
  }

  if (callLog.isIncoming(session)) {
    if (callLog.isAnswered()) {
      return (
        <IncomingSuccessIcon
          data-testid="call-log-icon-call-received"
          style={{
            height: `${size}px`,
            width: `${size}px`,
          }}
        />
      );
    }
    if (!callLog.isAnswered()) {
      return (
        <IncomingFailIcon
          data-testid="call-log-icon-call-missed"
          style={{
            height: `${size}px`,
            width: `${size}px`,
          }}
        />
      );
    }
  }

  return null;
};

export default React.memo(CallLogIcon);
