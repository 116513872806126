import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { deleteVoicemail, downloadVoicemail } from '../../../voicemails/actions/voicemailActions';
import ActivityEventOptions from '../activities/ActivityEventOptions';

const VoicemailOptions = ({ actions, hideOptionsButton, t, visible, voicemail }) => {
  const items = [
    {
      label: t('deleteVoicemail'),
      action: () => actions.deleteVoicemail(voicemail),
      enabled: true,
      testID: 'delete-voicemail',
    },
    {
      label: t('download'),
      enabled: true,
      action: () => {
        hideOptionsButton && hideOptionsButton();
        actions.downloadVoicemail(voicemail);
      },
    },
  ];

  return (
    <ActivityEventOptions
      hideOptionsButton={hideOptionsButton}
      items={items}
      testID="voicemail-options"
      visible={visible}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  actions: {
    deleteVoicemail: voicemail => dispatch(deleteVoicemail(voicemail)),
    downloadVoicemail: voicemail => dispatch(downloadVoicemail(voicemail)),
  },
});

export default withTranslation('activities')(connect(null, mapDispatchToProps)(VoicemailOptions));
