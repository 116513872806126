import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import CallIcon from '@mui/icons-material/Call';
import SipIcon from '@mui/icons-material/CallEnd';

import {
  getPresentialColor,
  getPresentialState,
  PRESENTIAL_STATES,
} from '../../../contact/selectors/contactSelector';
import colors from '../../../main/themes/colors';

const { BUSY, DND, NON_WEBRTC } = PRESENTIAL_STATES;

const DoNotDisturb = styled.div`
  height: 2px;
  width: 65%;
  background-color: white;
`;

const StateIndicator = styled.div`
  border-radius: 10000px;
  background-color: ${props => props.bgcolor};
  width: ${props => String(Math.floor(props.size / 2.7))}px;
  height: ${props => String(Math.floor(props.size / 2.7))}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCallIcon = styled(CallIcon)`
  && {
    fill: white;
    width: 9px;
    height: 9px;
  }
`;

const StyledSipIcon = styled(SipIcon)`
  && {
    fill: ${colors.primary};
    width: 9px;
    height: 9px;
  }
`;

const Icon = ({ presentialState }) => {
  switch (presentialState) {
    case DND:
      return <DoNotDisturb data-testid="icon-dnd" />;
    case BUSY:
      return <StyledCallIcon data-testid="icon-call" />;
    case NON_WEBRTC:
      return <StyledSipIcon data-testid="icon-cti" />;
    default:
      return null;
  }
};

export const ContactState = ({ presentialState, size = 100, ...rest }) => {
  const bgcolor = getPresentialColor(presentialState);
  return (
    <StateIndicator bgcolor={bgcolor} data-testid={`state-${bgcolor}`} size={size} {...rest}>
      <Icon presentialState={presentialState} />
    </StateIndicator>
  );
};

const mapStateToProps = (state, ownProps) => ({
  presentialState: getPresentialState(state, ownProps.contact),
});

export default connect(mapStateToProps)(React.memo(ContactState));
